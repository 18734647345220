.container {
  background-color: var(--color-secondary-2-1) !important;
  width: 100%;
  color: var(--color-primary-3) !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-block: 1vh;
}

.sectionTitle,
.sectionParagraph {
  /* font-family: creveland-midleton; */
  display: flex;
  justify-content: center;
  margin-bottom: 1vh;
}

.sectionTitle {
  font-size: 8vw;
  letter-spacing: 0px;
}

.sectionParagraph {
  font-size: 3vw;
  text-align: center;
  font-weight: 600;
  letter-spacing: 1px;
}

@media (min-width: 720px) {
  .sectionTitle {
    font-size: 6vw;
  }

  .sectionParagraph {
    font-size: 2.5vw;
  }
}

@media (min-width: 950px) {
  .sectionTitle {
    font-size: 4vw;
  }

  .sectionParagraph {
    font-size: 2vw;
  }
}

@media (min-width: 1400px) {
  .sectionTitle {
    font-size: 3.5vw;
  }

  .sectionParagraph {
    font-size: 1.5vw;
  }
}