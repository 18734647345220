.margined {
  margin-inline: 1vw;
  margin-top: 1vh
}

.buttonLegend {
  font-size: 1.25vw;
}

.buttonLinkLegend {
  font-size: 3.75vw;
}

@media (min-width: 440px) {
  .buttonLinkLegend {
    font-size: 3.75vw;
  }
}

@media (min-width: 600px) {
  .buttonLinkLegend {
    font-size: 3vw;
  }
}

@media (min-width: 1200px) {
  .buttonLegend {
    font-size: 1vw;
  }
}

@media (min-width: 1500px) {
  .buttonLegend {
    font-size: .75vw;
  }
}