/* latin */
@font-face {
  font-family: '__Handlee_1d284d';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/420908549fc4652d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Handlee_Fallback_1d284d';src: local("Arial");ascent-override: 105.86%;descent-override: 45.45%;line-gap-override: 0.00%;size-adjust: 88.23%
}.__className_1d284d {font-family: '__Handlee_1d284d', '__Handlee_Fallback_1d284d';font-weight: 400;font-style: normal
}

.SectionText_container__WwPJ0 {
  background-color: var(--color-secondary-2-1) !important;
  width: 100%;
  color: var(--color-primary-3) !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-block: 1vh;
}

.SectionText_sectionTitle__HS26H,
.SectionText_sectionParagraph__Yo7Sj {
  /* font-family: creveland-midleton; */
  display: flex;
  justify-content: center;
  margin-bottom: 1vh;
}

.SectionText_sectionTitle__HS26H {
  font-size: 8vw;
  letter-spacing: 0px;
}

.SectionText_sectionParagraph__Yo7Sj {
  font-size: 3vw;
  text-align: center;
  font-weight: 600;
  letter-spacing: 1px;
}

@media (min-width: 720px) {
  .SectionText_sectionTitle__HS26H {
    font-size: 6vw;
  }

  .SectionText_sectionParagraph__Yo7Sj {
    font-size: 2.5vw;
  }
}

@media (min-width: 950px) {
  .SectionText_sectionTitle__HS26H {
    font-size: 4vw;
  }

  .SectionText_sectionParagraph__Yo7Sj {
    font-size: 2vw;
  }
}

@media (min-width: 1400px) {
  .SectionText_sectionTitle__HS26H {
    font-size: 3.5vw;
  }

  .SectionText_sectionParagraph__Yo7Sj {
    font-size: 1.5vw;
  }
}
.Hamburger_iconWrapper__hbmwO {
  background-color: transparent;
  position: fixed;
  top: 1rem;
  right: 1rem;
  border-radius: 50%;
  height: 3.5rem;
  width: 3.5rem;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0 1rem 3rem rgba(255, 239, 207, 0.3);
  text-align: center;
  transition: all 0.3s;
}

.Hamburger_iconWrapper__hbmwO.Hamburger_scrolled__qm3tS {
  background-color: var(--color-secondary-2-2);
  top: 7rem;
}

.Hamburger_iconWrapper__hbmwO.Hamburger_clicked__oA5T4 {
  top: 5rem;
}

.Hamburger_icon__0YqcP {
  position: relative;
  background-color: var(--color-primary-4);
  width: 1.5rem;
  height: 2px;
  display: inline-block;
  margin-top: 1.75rem;
  transition: all 0.3s;
}

.Hamburger_icon__0YqcP.Hamburger_clicked__oA5T4 {
  background-color: transparent;
}

.Hamburger_icon__0YqcP::before,
.Hamburger_icon__0YqcP::after {
  content: "";
  background-color: var(--color-primary-4);
  width: 1.5rem;
  height: 2px;
  display: inline-block;
  position: absolute;
  left: 0;
  transition: all 0.3s;
}

.Hamburger_icon__0YqcP::before {
  top: -0.5rem;
  transform: rotate(0);
}

.Hamburger_icon__0YqcP.Hamburger_clicked__oA5T4::before {
  top: 0;
  transform: rotate(135deg);
}

.Hamburger_icon__0YqcP::after {
  top: 0.5rem;
  transform: rotate(0);
}

.Hamburger_icon__0YqcP.Hamburger_clicked__oA5T4::after {
  top: 0;
  transform: rotate(-135deg);
}

.Hamburger_iconWrapper__hbmwO:hover .Hamburger_icon__0YqcP::before {
  top: -.5rem;
}

.Hamburger_iconWrapper__hbmwO:hover .Hamburger_icon__0YqcP.Hamburger_clicked__oA5T4::before {
  top: 0;
}

.Hamburger_iconWrapper__hbmwO:hover .Hamburger_icon__0YqcP::after {
  top: .5rem;
}

.Hamburger_iconWrapper__hbmwO:hover .Hamburger_icon__0YqcP.Hamburger_clicked__oA5T4::after {
  top: 0;
}

.Hamburger_navBg__T3RHU {
  position: fixed;
    top: 6.5rem;
    right: 6.5rem;
    background-image: radial-gradient(
      var(--color-secondary-2-2),
      var(--color-secondary-2-1)
    );
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    z-index: 600;
    transform: scale(0);
    transition: transform 0.8s;
}

.Hamburger_navBg__T3RHU.Hamburger_clicked__oA5T4 {
  transform: scale(80);
}

.Hamburger_navContainer__1wvbW {
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 600;
  width: 0;
  opacity: 0;
  transition: width 0.8s, opacity 0.8s;
}

.Hamburger_navContainer__1wvbW.Hamburger_clicked__oA5T4 {
  width: 100%;
  opacity: 1;
}

.Hamburger_navContainer__1wvbW .Hamburger_list__LPnwE {
  position: absolute;
  list-style: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.Navbar_margined___YQ0O {
  margin-inline: 1vw;
  margin-top: 1vh
}

.Navbar_buttonLegend__cP1WB {
  font-size: 1.25vw;
}

.Navbar_buttonLinkLegend__ep_Td {
  font-size: 3.75vw;
}

@media (min-width: 440px) {
  .Navbar_buttonLinkLegend__ep_Td {
    font-size: 3.75vw;
  }
}

@media (min-width: 600px) {
  .Navbar_buttonLinkLegend__ep_Td {
    font-size: 3vw;
  }
}

@media (min-width: 1200px) {
  .Navbar_buttonLegend__cP1WB {
    font-size: 1vw;
  }
}

@media (min-width: 1500px) {
  .Navbar_buttonLegend__cP1WB {
    font-size: .75vw;
  }
}
.Countdown_countdownContainer___HSYY {
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.Countdown_countdownFull__9mqiC {
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
}

.Countdown_countdownMid__hJsaI {
  flex-direction: row;
  width: 60vw;
  justify-content: space-evenly;
}

.Countdown_heart__R0chV {
  position: relative;
  width: 100px;
  height: 90px;
  margin-top: 10px;
}

.Countdown_heart__R0chV::before, .Countdown_heart__R0chV::after {
  content: "";
  position: absolute;
  top: 0;
  width: 52px;
  height: 80px;
  border-radius: 50px 50px 0 0;
  background: #9e3636;
}

.Countdown_heart__R0chV::before {
  left: 50px;
  transform: rotate(-45deg);
  transform-origin: 0 100%;
}

.Countdown_heart__R0chV::after {
  left: 0;
  transform: rotate(45deg);
  transform-origin: 100% 100%;
}

.Countdown_countdownNumber__Y_ofN {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(3.5px);
          backdrop-filter: blur(3.5px);
  border: 1px solid rgba(255, 255, 255, 0.09);
  margin-bottom: 10px;
  padding-top: 0;
}

.Countdown_countdownHeartContainer__e89lj {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0);
  margin-bottom: 10px;
  z-index: 2;
  margin-top: -10px;
}

.Countdown_numberFont__qLUyk {
  display: flex;
  justify-content: center;
  align-self: center;
  font-size: 5vw;
  /* font-family: creveland-midleton !important; */
  font-weight: 700;
  color: var(--color-primary-1) !important;
}

.Countdown_heartNumberFont__AL60P {
  font-size: 5vw;
  /* font-family: creveland-midleton !important; */
  font-weight: bold;
  color: var(--color-primary-1) !important;
  justify-content: center;
  display: flex;
  z-index: 3;
  position: relative;
  padding-top: 0;
}

@media(min-width: 720px) {
  .Countdown_numberFont__qLUyk {
    font-size: 4vw;
  }

  .Countdown_heartNumberFont__AL60P {
    font-size: 4vw;
  }
}

@media(min-width: 900px) {
  .Countdown_numberFont__qLUyk {
    font-size: 3.5vw;
  }

  .Countdown_heartNumberFont__AL60P {
    font-size: 3.5vw;
  }
}

@media(min-width: 1200px) {
  .Countdown_numberFont__qLUyk {
    font-size: 2.75vw;
  }

  .Countdown_heartNumberFont__AL60P {
    font-size: 2.75vw;
  }
}

@media(min-width: 1500px) {
  .Countdown_numberFont__qLUyk {
    font-size: 2vw;
  }

  .Countdown_heartNumberFont__AL60P {
    font-size: 2vw;
  }
}

@media (max-width: 400px) {
  .Countdown_countdownNumber__Y_ofN, .Countdown_countdownHeartContainer__e89lj {
    width: 80px;
    height: 80px;
  }

  .Countdown_heart__R0chV {
    width: 80px;
    height: 70px;
  }

  .Countdown_heart__R0chV::before, .Countdown_heart__R0chV::after {
    width: 40px;
    height: 60px;
  }

  .Countdown_heart__R0chV::before {
    left: 40px;
  }
}
