.countdownContainer {
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.countdownFull {
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
}

.countdownMid {
  flex-direction: row;
  width: 60vw;
  justify-content: space-evenly;
}

.heart {
  position: relative;
  width: 100px;
  height: 90px;
  margin-top: 10px;
}

.heart::before, .heart::after {
  content: "";
  position: absolute;
  top: 0;
  width: 52px;
  height: 80px;
  border-radius: 50px 50px 0 0;
  background: #9e3636;
}

.heart::before {
  left: 50px;
  transform: rotate(-45deg);
  transform-origin: 0 100%;
}

.heart::after {
  left: 0;
  transform: rotate(45deg);
  transform-origin: 100% 100%;
}

.countdownNumber {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3.5px);
  border: 1px solid rgba(255, 255, 255, 0.09);
  margin-bottom: 10px;
  padding-top: 0;
}

.countdownHeartContainer {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0);
  margin-bottom: 10px;
  z-index: 2;
  margin-top: -10px;
}

.numberFont {
  display: flex;
  justify-content: center;
  align-self: center;
  font-size: 5vw;
  /* font-family: creveland-midleton !important; */
  font-weight: 700;
  color: var(--color-primary-1) !important;
}

.heartNumberFont {
  font-size: 5vw;
  /* font-family: creveland-midleton !important; */
  font-weight: bold;
  color: var(--color-primary-1) !important;
  justify-content: center;
  display: flex;
  z-index: 3;
  position: relative;
  padding-top: 0;
}

@media(min-width: 720px) {
  .numberFont {
    font-size: 4vw;
  }

  .heartNumberFont {
    font-size: 4vw;
  }
}

@media(min-width: 900px) {
  .numberFont {
    font-size: 3.5vw;
  }

  .heartNumberFont {
    font-size: 3.5vw;
  }
}

@media(min-width: 1200px) {
  .numberFont {
    font-size: 2.75vw;
  }

  .heartNumberFont {
    font-size: 2.75vw;
  }
}

@media(min-width: 1500px) {
  .numberFont {
    font-size: 2vw;
  }

  .heartNumberFont {
    font-size: 2vw;
  }
}

@media (max-width: 400px) {
  .countdownNumber, .countdownHeartContainer {
    width: 80px;
    height: 80px;
  }

  .heart {
    width: 80px;
    height: 70px;
  }

  .heart::before, .heart::after {
    width: 40px;
    height: 60px;
  }

  .heart::before {
    left: 40px;
  }
}