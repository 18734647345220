.iconWrapper {
  background-color: transparent;
  position: fixed;
  top: 1rem;
  right: 1rem;
  border-radius: 50%;
  height: 3.5rem;
  width: 3.5rem;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0 1rem 3rem rgba(255, 239, 207, 0.3);
  text-align: center;
  transition: all 0.3s;
}

.iconWrapper.scrolled {
  background-color: var(--color-secondary-2-2);
  top: 7rem;
}

.iconWrapper.clicked {
  top: 5rem;
}

.icon {
  position: relative;
  background-color: var(--color-primary-4);
  width: 1.5rem;
  height: 2px;
  display: inline-block;
  margin-top: 1.75rem;
  transition: all 0.3s;
}

.icon.clicked {
  background-color: transparent;
}

.icon::before,
.icon::after {
  content: "";
  background-color: var(--color-primary-4);
  width: 1.5rem;
  height: 2px;
  display: inline-block;
  position: absolute;
  left: 0;
  transition: all 0.3s;
}

.icon::before {
  top: -0.5rem;
  transform: rotate(0);
}

.icon.clicked::before {
  top: 0;
  transform: rotate(135deg);
}

.icon::after {
  top: 0.5rem;
  transform: rotate(0);
}

.icon.clicked::after {
  top: 0;
  transform: rotate(-135deg);
}

.iconWrapper:hover .icon::before {
  top: -.5rem;
}

.iconWrapper:hover .icon.clicked::before {
  top: 0;
}

.iconWrapper:hover .icon::after {
  top: .5rem;
}

.iconWrapper:hover .icon.clicked::after {
  top: 0;
}

.navBg {
  position: fixed;
    top: 6.5rem;
    right: 6.5rem;
    background-image: radial-gradient(
      var(--color-secondary-2-2),
      var(--color-secondary-2-1)
    );
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    z-index: 600;
    transform: scale(0);
    transition: transform 0.8s;
}

.navBg.clicked {
  transform: scale(80);
}

.navContainer {
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 600;
  width: 0;
  opacity: 0;
  transition: width 0.8s, opacity 0.8s;
}

.navContainer.clicked {
  width: 100%;
  opacity: 1;
}

.navContainer .list {
  position: absolute;
  list-style: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
}